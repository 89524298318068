<template>
  <div class="container mx-auto py-20">
    <!-- component -->
    <div class="mt-40 sm:mt-0">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="mb-10 px-4 sm:px-0">
            <h3 class="text-3xl font-medium leading-6 text-gray-900">
              {{ t('Thank you for using our service') }}
            </h3>
            <p class="my-4 text-base text-gray-600 w-1/2 mx-auto">
              {{
                t(
                  'We are waiting if you start to use our service in the future.'
                )
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { defineComponent } from 'vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  setup() {
    const { t } = useTranslation()
    return {
      t,
    }
  },
})
</script>
